<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mt-5"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'login' }" class="no-caps" :ripple="false">
            {{ $t('log_in') }}
            <!-- <v-icon size="24">mdi-account</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'password.email' }" class="no-caps" :ripple="false">
            {{ $t('new_password') }}
            <!-- <v-icon size="24">mdi-key-variant</v-icon> -->
          </v-tab>
        </v-tabs>

        <v-card class="mt-5 content-tabs-card px-5 py-5 rounded">
          <ValidationObserver ref="form1" v-slot="{ invalid }">
            <v-form
              :model="form1" 
              @submit.prevent="submit('form1')"
              autocomplete="off"
              method="post"
            >
              <h1 class="text-display-2 font-weight-medium mb-5">{{ $t('set_a_new_password') }}</h1>
              <v-alert
                :value="invalidToken"
                type="error"
                tile
                class="mb-4"
              >
                {{ $t('invalid_token') }}
              </v-alert>
              <el-alert
                type="error"
                v-if="form1.hasError && !form1.success"
                class="mb-4"
                tile
                show-icon
              >
                {{ $t('correct_errors') }}
              </el-alert>
              <x-password
                v-if="!invalidToken"
                v-model="form1.password"
                ref="form1.password"
                id="form1.password"
                :label="$t('enter_new_password')"
                :placeholder="$t('enter_new_password')"
                :name="$t('password')"
                rules="required|min:8|max:24"
              />
              <v-btn v-if="!invalidToken" color="primary" class="no-caps mt-3" rounded block x-large depressed :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('update_password') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
            </v-form>
          </ValidationObserver>
        </v-card>

      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    topBgImgs: [],
    invalidToken: false,
    form1: {
      loading: false,
      password: '',
      hasError: false,
      errors: {},
      success: false
    }
  }),
  created() {

    // Verify token
    let token = this.$route.params.token
    this.axios
      .post('/auth/password/reset/validate-token', {
        locale: this.$i18n.locale,
        token: token
      })
      .then(response => {
        if (response.data.status === 'success') {
          this.invalidToken = false
        } else {
          this.invalidToken = true
        }
      })
      .catch(() => {
        this.invalidToken = true
      })
  },
  methods: {
    async submit (formName) {
      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.axios
        .post('/auth/password/update', {
          locale: this.$i18n.locale,
          password: form.password,
          token: this.$route.params.token
        })
        .then(response => {
          if (response.data.status === 'success') {
            this.$router.push({name: 'login', params: {successResetUpdateRedirect: true}})
          }
        })
        .catch(error => {
          form.hasError = true
          form.errors = error.response.data.errors || {}
        })
        .finally(() => form.loading = false)
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 960) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>